import { Routes } from "@angular/router";
import {
  ABOUT_US,
  FAQ,
  HOME,
  LEGAL,
  PRIVACY_POLICY,
  TERMS_OF_USE,
} from "./constants/routes";

export const ROUTES: Routes = [
  { path: "", redirectTo: '', pathMatch: "full" },
  {
    path: '',
    loadComponent: () => import("./features/home/home.component").then((c) => c.HomeComponent),
  },
  {
    path: ABOUT_US.path,
    loadComponent: () =>
      import("./features/about-us/about-us.component").then(
        (m) => m.AboutUsComponent
      ),
  },
  {
    path: PRIVACY_POLICY.path,
    loadComponent: () =>
      import("./features/privacy-policy/privacy-policy.component").then(
        (m) => m.PrivacyPolicyComponent
      ),
  },
  {
    path: TERMS_OF_USE.path,
    loadComponent: () =>
      import("./features/terms-of-use/terms-of-use.component").then(
        (m) => m.TermsOfUseComponent
      ),
  },
  {
    path: LEGAL.path,
    loadComponent: () =>
      import("./features/legal-users-agreement/legal-users-agreement.component").then(
        (m) => m.LegalUsersAgreementComponent
      ),
  },
  {
    path: FAQ.path,
    loadComponent: () =>
      import("./features/faq/faq.component").then((c) => c.FaqComponent),
  },
  {
    path: "**",
    loadComponent: () =>
      import("./features/not-found/not-found.component").then(
        (c) => c.NotFoundComponent
      ),
  },
];
