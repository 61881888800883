import { Pipe, PipeTransform } from "@angular/core";
import * as routes from "../../constants/routes";

@Pipe({
  name: "absolutePath",
  standalone: true,
})
export class AbsolutePathPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case "ABS_HOME":
        return "";
      case "ABS_ABOUT_US":
        return "/about-us";
      case "ABS_TERMS":
        return "/terms";
      case "ABS_PRIVACY_POLICY":
        return "/privacy-policy";
      case "ABS_LEGAL":
        return "/agreements";
      case "ABS_FAQ":
        return "/faq";
      default:
        return "/";
    }
  }
}
