<header class="header" [class.sidebar-open]="isSidebarOpen">
    <div class="containerBox inner">
        <div class="space-between">
            <a href="javascript:void(0);" class="logo_wrapper" aria-label="UnSpoken Memories home page">
                <img src="assets/images/logo.svg" alt="UnSpoken Memories Logo">
            </a>
            <button class="menu" (click)="toggleSidebar()" aria-label="Toggle navigation"></button>
            <nav class="sidebar" aria-label="Main Navigation">
                <ul class="nav_list space-between">
                    @if(id !=null){
                    <li><a [routerLink]="['ABS_HOME' | absolutePath]" [routerLinkActiveOptions]="{ exact: true }"
                            [ngClass]="{'active':id}" [queryParams]="{memoryId:id}" routerLinkActive="active"
                            (click)="closeSidebar()" class="nav_link link">Home</a></li>
                    }@else {
                    <li><a [routerLink]="['ABS_HOME' | absolutePath]" [routerLinkActiveOptions]="{ exact: true }"
                            [ngClass]="{'active':id}" routerLinkActive="active" (click)="closeSidebar()"
                            class="nav_link link">Home</a></li>
                    }

                    <li><a [routerLink]="['ABS_ABOUT_US' | absolutePath]" routerLinkActive="active"
                            class="nav_link link" (click)="closeSidebar()">About Us</a></li>
                    <li><a [routerLink]="['ABS_LEGAL' | absolutePath]" routerLinkActive="active" class="nav_link link"
                            (click)="closeSidebar()">Legal Users Agreement</a></li>
                    <li><a [routerLink]="['ABS_PRIVACY_POLICY' | absolutePath]" routerLinkActive="active"
                            class="nav_link link" (click)="closeSidebar()">Privacy Policy</a></li>
                    <li><a [routerLink]="['ABS_TERMS' | absolutePath]" routerLinkActive="active" class="nav_link link"
                            (click)="closeSidebar()">Terms Of Use</a></li>
                    <li><a [routerLink]="['ABS_FAQ' | absolutePath]" routerLinkActive="active" class="nav_link link"
                            aria-current="page" (click)="closeSidebar()">FAQ</a></li>
                </ul>
            </nav>
        </div>
        <div class="backdrop" *ngIf="isSidebarOpen" (click)="closeSidebar()"></div>
    </div>
</header>