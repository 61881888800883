<footer class="footer">
    <div class="containerBox">
        <div class="footer_row row">
            <div class="col-4 col-lg-12 mb-lg-30 logo_box">
                <a href="javascript:void(0);" class="logo_wrapper mb-30" aria-label="UnSpoken Memories home page">
                    <img src="assets/images/logo-white.svg" alt="UnSpoken Memories Logo">
                </a>
                <div class="download-section">
                    <h2 class="fs_14 fw400 mb-15">Download the app by clicking the link below:</h2>
                    <div class="app-links justify-start">
                        <a class="app-link" aria-label="Get it on Google Play"
                            (click)="comingSoon()" rel="noopener">
                            <img src="assets/images/google-play.svg" alt="Google Play logo" />
                        </a>
                        <a class="app-link" aria-label="Download on the App Store"
                            (click)="comingSoon()" rel="noopener">
                            <img src="assets/images/app-store.svg" alt="Apple App Store logo" />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-8 col-lg-12">
                <div class="row footer_links_row">
                    <div class="column" aria-labelledby="features-heading">
                        <h3 id="features-heading">Features</h3>
                        <ul class="footer_links">
                            <li><a class="link">Create Memory</a></li>
                            <li><a class="link">Publish Memory</a></li>
                            <li><a class="link">Draft Memory</a></li>
                        </ul>
                    </div>
                    <div class="column" aria-labelledby="company-heading">
                        <h3 id="company-heading">Company</h3>
                        <ul class="footer_links">
                            <li><a [routerLink]="['ABS_ABOUT_US' | absolutePath]" class="link">About Us</a></li>
                            <li><a [routerLink]="['ABS_LEGAL' | absolutePath]" class="link">Legal Users Agreement</a></li>
                            <li><a [routerLink]="['ABS_PRIVACY_POLICY' | absolutePath]" class="link">Privacy Policy</a></li>
                            <li><a [routerLink]="['ABS_TERMS' | absolutePath]" class="link">Terms of Use</a></li>

                        </ul>
                    </div>
                    <div class="column" aria-labelledby="contact-heading">
                        <h3 id="contact-heading">Contact</h3>
                        <ul class="footer_links">
                            <li>
                                <a href="tel:+1213434344">
                                    <img src="assets/images/phone.svg" alt="" role="presentation" />
                                    +1 213434344
                                </a>
                            </li>
                            <li>
                                <a class="underline" href="matilto:inquiries@unspokenmemories.com">
                                    <img src="assets/images/mail.svg" alt="" role="presentation" />
                                    inquiries&#64;unspokenmemories.com
                                </a>
                            </li>
                            <li>
                                <address>
                                    <img src="assets/images/location.svg" alt="" role="presentation" />
                                    2972 Westheimer Rd. Santa Ana, Illinois 85486
                                </address>
                            </li>
                        </ul>
                    </div>
                    <div class="column" aria-labelledby="social-heading">
                        <h3 id="social-heading">Social media</h3>
                        <ul class="social-links">
                            <li>
                                <a aria-label="Visit our facebook page" target="_blank" rel="noopener">
                                    <img src="assets/images/facebook.svg" alt="Facebook">
                                </a>
                            </li>
                            <li>
                                <a aria-label="Visit our twitter page" [href]="socialLinks.TWITTER"  target="_blank" rel="noopener">
                                    <img src="assets/images/twitter.svg" alt="Twitter">
                                </a>
                            </li>
                            <li>
                                <a aria-label="Visit our linkedin page" [href]="socialLinks.LINKEDIN" target="_blank" rel="noopener">
                                    <img src="assets/images/linkedin.svg" alt="LinkedIn">
                                </a>
                            </li>
                            <li>
                                <a aria-label="Visit our instagram page" [href]="socialLinks.INSTAGRAM"  target="_blank" rel="noopener">
                                    <img src="assets/images/instagram.svg" alt="Instagram">
                                </a>
                            </li>
                            <li>
                                <a aria-label="Visit our instagram page" [href]="socialLinks.YOUTUBE"  target="_blank" rel="noopener">
                                    <img src="assets/images/youtube.svg" class="youtubeImage" alt="YouTube">
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright_row flex-center">
            <span class="badge fs_16">Patent Pending</span>
            <p class="fs_16">&copy; Copyright UnSpoken Memories LTD. All rights reserved.</p>
        </div>
    </div>
</footer>