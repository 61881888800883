export const environment = {
  production: false,
  API_KEY: '1234',
  API_BASE_PATH: 'https://unspokenqa-api.appskeeper.in/unspoken_memories/',
  // API_BASE_PATH: 'https://unspokendev-api.appskeeper.in/unspoken_memories/',

  s3: {
    AWS_ACCESS_KEY: '',
    AWS_SECRET_KEY: '',
    REGION: '',
    BUCKET_NAME: '',
    IMAGE_PREFIX: 'https://dm1zk12gu6075.cloudfront.net/'
  },
};
